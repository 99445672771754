import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import RenderSelection from 'components/Forms/ReduxFormFields/RenderSelection';

const InputRadio = props => {
  const { name } = props;

  return <Field {...props} component={RenderSelection} name={name} type="radio" />;
};

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputRadio;
