export const optionRequired = value => {
  if (value === undefined) {
    return 'Please select one';
  }
};

export const inputRequired =
  (validationMessage = 'Value is undefined') =>
  value =>
    value ? undefined : validationMessage;
