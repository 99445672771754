import React from 'react';
import { Field } from 'redux-form';

import RenderRadioGroup from 'components/Forms/ReduxFormFields/RenderRadioGroup';
import { optionRequired } from 'components/Forms/ReduxFormFields/validation';

const InputRadioGroup = props => (
  <Field component={RenderRadioGroup} validate={optionRequired} {...props} />
);

export default InputRadioGroup;
