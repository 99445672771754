import React from 'react';
import PropTypes from 'prop-types';

import Selection from 'components/Forms/utilities/Selection';

const toBoolean = value => {
  if (value === 'false') return false;
  if (value === 'true') return true;
  return value;
};

const RenderSelection = ({ input, ...rest }) => (
  <Selection
    {...input}
    {...rest}
    input={input}
    value={input.value}
    onChange={(event, value) => input.onChange(toBoolean(value))}
  />
);

RenderSelection.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
};

export default RenderSelection;
