import React from 'react';

import InputRadioGroup from 'components/Forms/FormFields/InputRadioGroup';
import SubText from 'components/Forms/utilities/SubText';

const toBoolean = value => {
  if (value === 'false') return false;
  if (value === 'true') return true;
  return value;
};

const RenderRadioGroup = props => {
  const {
    input,
    type,
    invalidText,
    helpText,
    id,
    meta: { touched, error, invalid },
  } = props;

  let invalidMessage;

  if (touched && invalid && type === 'radio') {
    invalidMessage = invalidText;
  }

  return (
    <React.Fragment>
      <InputRadioGroup
        {...input}
        {...props}
        onChange={value => input.onChange(toBoolean(value))}
        invalidText={touched ? error : null}
      >
        {props.children}
      </InputRadioGroup>
      <SubText id={`${id}-subtext`} invalidText={invalidMessage} helpText={helpText} />
    </React.Fragment>
  );
};

export default RenderRadioGroup;
