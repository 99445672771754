import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import RenderSelection from 'components/Forms/ReduxFormFields/RenderSelection';

const InputCheckbox = props => {
  return (
    <Field
      {...props}
      component={RenderSelection}
      type="checkbox"
      data-testid="react-form-input-checkbox"
    />
  );
};

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputCheckbox;
