import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import styles from 'components/Forms/utilities/Textbox/Textbox.scss';

const PasswordToggle = props => {
  const { onClick, onBlur, errorPresent, passwordIsVisible, passwordToggleClicked } = props;

  return (
    <Fragment>
      <button
        className={classnames(styles.passwordToggle, { [styles.errorPresent]: errorPresent })}
        onClick={e => {
          onClick(e);
        }}
        onBlur={() => {
          onBlur();
        }}
        aria-label="Toggle password visibility"
        type="button"
      >
        {(passwordIsVisible && 'Hide') || 'Show'}
      </button>
      <span className="sr-only" role={passwordToggleClicked ? 'alert' : null}>
        {`Password ${(passwordIsVisible && 'visible') || 'hidden'}`}
      </span>
    </Fragment>
  );
};

PasswordToggle.propTypes = {
  passwordIsVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  passwordToggleClicked: PropTypes.bool.isRequired,
  errorPresent: PropTypes.bool,
};

PasswordToggle.defaultProps = {
  errorPresent: false,
};

export default PasswordToggle;
