import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/Forms/utilities/EndAdornment/EndAdornment.scss';

/**
 * EndAdornment component: used in conjunction with textfields to allow insertion
 * of a symbol/image on the right inner side.
 */
const EndAdornment = ({ children }) => (
  <span className={styles.endAdornment} data-testid="end-adornment-wrapper">
    {children}
  </span>
);

EndAdornment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default EndAdornment;
