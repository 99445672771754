import React from 'react';
import { func, string, bool, shape } from 'prop-types';

import AddButton from '@johnlewispartnership/wtr-ingredients/ingredients/AddButton';

import SubText from 'components/Forms/utilities/SubText';

import styles from './styles.scss';

const RenderAddButton = props => {
  const {
    id,
    input: { name },
    label,
    meta: { touched, error },
    onClick,
    ...rest
  } = props;

  return (
    <div className={touched && error ? styles.error : ''}>
      <AddButton
        {...{
          id,
          label,
          name,
          onClick,
          ...rest,
        }}
      />
      {touched && error && (
        <div className={styles.subtext}>
          <SubText invalidText={error} id="add-button-error" />
        </div>
      )}
    </div>
  );
};

RenderAddButton.propTypes = {
  id: string,
  input: shape({
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: shape({
    touched: bool,
    error: string,
  }).isRequired,
  onClick: func,
};

RenderAddButton.defaultProps = {
  id: 'add-button',
  label: '',
  onClick: () => {},
};

export default RenderAddButton;
