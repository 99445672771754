import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import RenderTextbox from 'components/Forms/ReduxFormFields/RenderTextbox';

const InputText = props => {
  return <Field {...props} component={RenderTextbox} type="text" />;
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputText;
