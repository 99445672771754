import React from 'react';
import PropTypes from 'prop-types';

import Textbox from 'components/Forms/utilities/Textbox';

const RenderTextbox = ({ input, ...rest }) => <Textbox {...input} {...rest} />;

RenderTextbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

export default RenderTextbox;
