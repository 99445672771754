import React from 'react';
import { func, string, arrayOf } from 'prop-types';
import { Field } from 'redux-form';

import RenderAddButton from 'components/Forms/ReduxFormFields/AddButton/RenderAddButton';

const AddButtonFormField = props => {
  const { validate } = props;
  return <Field component={RenderAddButton} validate={validate} {...props} />;
};

AddButtonFormField.propTypes = {
  validate: arrayOf(func),
  label: string,
  onClick: func,
  name: string,
  id: string,
};

AddButtonFormField.defaultProps = {
  validate: [],
  label: '',
  onClick: () => {},
  name: 'add-button',
  id: 'add-button',
};

export default AddButtonFormField;
